import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, Toolbar, List, Typography, Divider, IconButton, Badge, Container, Grid, Paper, Link } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Copyright from '../common/copyright';
import Menu from '../common/menu';
import Header from '../common/header';
import tokenService from '../../utils/services/token.service';
import { useHistory } from 'react-router-dom';


export default function VIP() {
    const history = useHistory();

    const [initialLaunch, setInitialLaunch] = React.useState(true);

    const initState = async () => {
        const token = await tokenService.getToken();
        if (token == null) {
            // Redirect user to login
            history.push("/login");
        }
    };

    React.useEffect(() => {
        initState();
    }, []);

    const onEntranceVideoEnd = async () => {
        setInitialLaunch(false);
        history.push("/lobby?vip_user=false");
    };

    return (
        <>
            {initialLaunch ? (
                <video
                    autoPlay
                    muted
                    width="100%"
                    height="auto"
                    style={{
                        margin: '0 auto',
                        display: 'block',
                    }}
                    onEnded={onEntranceVideoEnd}
                >
                    <source src='./assets/videos/lobby-to-vip-door.mp4' type='video/mp4' />
                    Your device does not support 3d rendering. Please try accessing the platform in another device.
                </video>
            ) : (
                <></>
            )}
        </>
    );
}
import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, Toolbar, List, Typography, Divider, IconButton, Badge, Container, Grid, Paper, Link } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Copyright from '../common/copyright';
import Menu from '../common/menu';
import Header from '../common/header';
import tokenService from '../../utils/services/token.service';
import { useHistory } from 'react-router-dom';
import MiniBlinkBox from '../common/elements/mini.blinkbox';


export default function Exhibition() {
    const history = useHistory();

    const [initialLaunch, setInitialLaunch] = React.useState(true);

    const [blinkMatrix, setBlinkMatrix] = React.useState({
        t1: [200, 300],
        t2: [500, 300],
        t3: [-600, 300],
        t4: [-300, 300],
        b1: [90, 500],
        b2: [500, 500],
        b3: [-550, 500],
        b4: [-200, 500]
    });

    const initState = async () => {
        const token = await tokenService.getToken();
        if (token == null) {
            // Redirect user to login
            history.push("/login");
        }
    };

    React.useEffect(() => {
        initState();
        const windowWidth = window.innerWidth;

        let bM = {
            t1: [windowWidth / 7.2, windowWidth / 4.8],
            t2: [windowWidth / 2.88, windowWidth / 4.8],
            t3: [-(windowWidth / 2.4), windowWidth / 4.8],
            t4: [-(windowWidth / 4.8), windowWidth / 4.8],
            b1: [windowWidth / 16, windowWidth / 2.88],
            b2: [windowWidth / 2.88, windowWidth / 2.88],
            b3: [-(windowWidth / 2.61818182), windowWidth / 2.88],
            b4: [-(windowWidth / 7.2), windowWidth / 2.88],
        };
        setBlinkMatrix(bM);
    }, []);

    const onEntranceVideoEnd = async () => {
        setInitialLaunch(false);
    };

    return (
        <>
            {initialLaunch ? (
                <video
                    autoPlay
                    muted
                    width="100%"
                    height="auto"
                    style={{
                        margin: '0 auto',
                        display: 'block',
                    }}
                    onEnded={onEntranceVideoEnd}
                >
                    <source src='./assets/videos/lobby-to-exhibition-door.mp4' type='video/mp4' />
                    Your device does not support 3d rendering. Please try accessing the platform in another device.
                </video>
            ) : (
                <>
                    <Menu bgColor={false} />
                    <Header />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} sx={{
                            overflowY: 'hidden',
                            height: "100%"
                        }}>
                            <MiniBlinkBox y={blinkMatrix.t1[1]} x={blinkMatrix.t1[0]} nav={'/booth/618ae4f6f597077834fde089'} title={'Go to the PureSoftware Booth'} />
                            <MiniBlinkBox y={blinkMatrix.t2[1]} x={blinkMatrix.t2[0]} nav={'/booth/618ae50df597077834fde08b'} title={'Go to the Seamfix Booth'} />
                            <MiniBlinkBox y={blinkMatrix.t3[1]} x={blinkMatrix.t3[0]} nav={'/booth/618ae51df597077834fde08d'} title={'Go to the KPMG Booth'} />
                            <MiniBlinkBox y={blinkMatrix.t4[1]} x={blinkMatrix.t4[0]} nav={'/booth/618ae52ef597077834fde08f'} title={'Go to the Absa Bank Booth'} />
                            <MiniBlinkBox y={blinkMatrix.b1[1]} x={blinkMatrix.b1[0]} nav={'/booth/618ae53ff597077834fde091'} title={'Go to the Huawei Booth'} />
                            <MiniBlinkBox y={blinkMatrix.b2[1]} x={blinkMatrix.b2[0]} nav={'/booth/618ae552f597077834fde093'} title={'Go to the Microsoft Booth'} />
                            <MiniBlinkBox y={blinkMatrix.b3[1]} x={blinkMatrix.b3[0]} nav={'/booth/618ae55ff597077834fde095'} title={'Go to the Profinch Booth'} />
                            <MiniBlinkBox y={blinkMatrix.b4[1]} x={blinkMatrix.b4[0]} nav={'/booth/618ae599f597077834fde097'} title={'Go to the Temenos Booth'} />

                            <video
                                autoPlay
                                muted
                                loop
                                width="100%"
                                height="auto"
                                style={{
                                    margin: '0 auto',
                                    display: 'block',
                                }}
                            >
                                <source src='./assets/videos/exhibition-still.mp4' type='video/mp4' />
                                Your device does not support 3d rendering. Please try accessing the platform in another device.
                            </video>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
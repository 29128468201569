import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, Toolbar, List, Typography, Divider, IconButton, Badge, Container, Grid, Paper, Link, Alert, Snackbar, Modal } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Copyright from '../common/copyright';
import Menu from '../common/menu';
import Header from '../common/header';
import { useHistory } from 'react-router-dom';
import tokenService from '../../utils/services/token.service';
import userService from '../../utils/services/user.service';
import UserCard from './user-card';
import briefcaseService from '../../utils/services/briefcase.service';
import { IContact } from '../../utils/interfaces/contact.interface';
import { IUser } from '../../utils/interfaces/user.interface';


export default function Users() {
    const history = useHistory();
    const [showError, setShowError] = React.useState(false);
    const [showMessage, setShowMessage] = React.useState(false);
    const [users, setUsers] = React.useState([]);
    const [contacts, setContacts] = React.useState([]);
    const [selfUserId, setSelfUserId] = React.useState("");
    const [openModal, setOpenModal] = React.useState(false);
    const [viewUser, setViewUser] = React.useState<IUser>();

    const handleOpen = (user: IUser) => {
        setViewUser(user);
        // setOpenModal(true);
        console.log("Clicked on ", user);
    };

    const handleClose = () => setOpenModal(false);

    const initState = async () => {
        try {
            const token = await tokenService.getToken();
            if (token == null) {
                // Redirect user to login
                history.push("/login");
            } else {
                // Fetch User details
                const response = await userService.getAllUsers();
                if (response == null) {
                    // User not found. Redirect user to login
                    setShowError(true);
                    setTimeout(async () => {
                        await tokenService.clearData();
                        history.push("/login");
                    }, 2000);
                } else {
                    const users = response.data;
                    setUsers(users);

                    // Check if contact already exists in the briefcase
                    const res = await briefcaseService.getMyBriefcase();
                    if (res != null) {
                        const briefcase = res.data;
                        const contacts = briefcase.contacts || [];
                        setContacts(contacts);
                    }

                    // Fetch user's ID
                    const userJson = await tokenService.getKey("user") || "";
                    const user = JSON.parse(userJson);
                    setSelfUserId(user._id.toString());
                }
            }
        } catch (e) {
            console.log(e);
            setShowError(true);
            setTimeout(async () => {
                await tokenService.clearData();
                history.push("/login");
            }, 2000);
        }
    };

    React.useEffect(() => {
        initState();
    }, []);

    return (
        <>
            <Menu />
            <Header />
            <Snackbar open={showError} autoHideDuration={3000} onClose={() => { setShowError(false) }}>
                <Alert severity="warning" sx={{ width: '100%' }} onClose={() => { setShowError(false) }}>
                    Something went wrong. Please login again.
                </Alert>
            </Snackbar>
            <Snackbar open={showMessage} autoHideDuration={3000} onClose={() => { setShowMessage(false) }}>
                <Alert severity="success" sx={{ width: '100%' }} onClose={() => { setShowMessage(false) }}>
                    Profile updated successfully.
                </Alert>
            </Snackbar>
            <Container disableGutters maxWidth="md" component="main" sx={{ pt: 13, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    All Delegates
                </Typography>
                <Grid container spacing={2} sx={{}}>
                    {users.map((user: any) => {
                        let userAddedToBC = false;
                        let selfCard = false;
                        contacts.map((contact: { user: IContact }) => {
                            if (contact?.user?._id?.toString() == user?._id?.toString()) {
                                userAddedToBC = true;
                            }
                        });
                        if (selfUserId?.toString() == user?._id?.toString()) {
                            selfCard = true;
                        }
                        return (
                            <Grid item xs={12} sm={4} key={user._id}>
                                <UserCard firstName={user.firstName} lastName={user.lastName} profileImage={user.profileImage} designation={user.professionalInfo?.designation} company={user.professionalInfo?.company} socialLinkedIn={user.social?.linkedin} userId={user._id} userAddedToBC={userAddedToBC} selfCard={selfCard} onClickView={() => { handleOpen }} user={user} />
                            </Grid>
                        );
                    })}
                </Grid>
                {/* Visiting Card Popup Modal */}
                <Modal
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {viewUser?._id}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography>
                    </Box>
                </Modal>
            </Container>
            {/* Footer */}
            <Container
                maxWidth="md"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 6,
                    py: [3, 6],
                }}
            >
                <Grid container spacing={4} justifyContent="space-evenly">

                </Grid>
                <Copyright />
            </Container>
            {/* End footer */}
        </>
    );
}
import axios from "axios";
import CONSTANTS from './../constants';
import tokenService from "./token.service";

const API_URL = CONSTANTS.API_URL + '/user/';

export default {
    getMyDetails: async () => {
        try {
            const token = await tokenService.getToken();
            return await axios.get(API_URL + "get_my_profile", {
                headers: { Authorization: 'Bearer ' + token }
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    getUserDetails: async (userId: string) => {
        try {
            const token = await tokenService.getToken();
            return await axios.get(API_URL + "get_user_profile?userId=" + userId, {
                headers: { Authorization: 'Bearer ' + token }
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    updateMyProfile: async (firstName: string, lastName: string, mobileNumber: string, dob: number, profileImage: string, gender: string, designation: string, company: string, aboutMe: string, socialLinkedIn: string) => {
        try {
            const data = {
                "firstName": firstName,
                "lastName": lastName,
                "mobileNumber": mobileNumber,
                "dob": dob,
                "profileImage": profileImage,
                "gender": gender,
                "designation": designation,
                "company": company,
                "aboutMe": aboutMe,
                "socialLinkedIn": socialLinkedIn
            };
            const token = await tokenService.getToken();
            return await axios.post(API_URL + "update_my_profile", data, {
                headers: { Authorization: 'Bearer ' + token }
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    getAllUsers: async () => {
        try {
            const token = await tokenService.getToken();
            return await axios.get(API_URL + "all_users", {
                headers: { Authorization: 'Bearer ' + token }
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
}
import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, Toolbar, List, Typography, Divider, IconButton, Badge, Container, Grid, Paper, Link, AppBar, Button, Card, CardActions, CardContent, CardHeader, GlobalStyles, Checkbox, FormControlLabel, TextField, FormControl, InputLabel, MenuItem, Select, Snackbar, Alert } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import ButtonUnstyled from '@mui/core/ButtonUnstyled';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Copyright from '../common/copyright';
import FooterMenu from '../common/menu';
import Header from '../common/header';
import { useHistory } from 'react-router-dom';
import tokenService from '../../utils/services/token.service';
import userService from '../../utils/services/user.service';
import { DropzoneArea } from 'material-ui-dropzone'


export default function User() {
    const history = useHistory();
    const [fullName, setFullName] = React.useState("");
    const [mobileNumber, setMobileNumber] = React.useState("");
    const [dob, setDob] = React.useState<number>(0);
    const [profileImage, setProfileImage] = React.useState("");
    const [gender, setGender] = React.useState("");
    const [designation, setDesignation] = React.useState("");
    const [company, setCompany] = React.useState("");
    const [aboutMe, setAboutMe] = React.useState("");
    const [socialLinkedIn, setSocialLinkedIn] = React.useState("");
    const [showError, setShowError] = React.useState(false);
    const [showMessage, setShowMessage] = React.useState(false);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        console.log(fullName);
        const firstName = fullName.split(" ")[0];
        let lastNameArray = fullName.split(" ");
        lastNameArray.splice(0, 1);
        const lastName = (lastNameArray.length > 0 ? lastNameArray.join(" ") : "");

        const epochDob = dob || 0;
        await userService.updateMyProfile(firstName, lastName, mobileNumber, epochDob, profileImage, gender, designation, company, aboutMe, socialLinkedIn);
        setShowMessage(true);
    }

    const initState = async () => {
        const token = await tokenService.getToken();
        if (token == null) {
            // Redirect user to login
            history.push("/login");
        } else {
            // Fetch User details
            const response = await userService.getMyDetails();
            if (response == null) {
                // User not found. Redirect user to login
                setShowError(true);
                setTimeout(async () => {
                    await tokenService.clearData();
                    history.push("/login");
                }, 2000);
            } else {
                const user = response.data;
                setFullName(user.firstName + " " + user.lastName);
                setMobileNumber(user.mobileNumber);
                setDob(user.dob);
                setProfileImage(user.profileImage);
                setGender(user.gender);
                setDesignation(user.professionalInfo?.designation || "");
                setCompany(user.professionalInfo?.company || "");
                setAboutMe(user.professionalInfo?.aboutMe || "");
                setSocialLinkedIn(user.social?.linkedin || "");
            }
        }
    };

    React.useEffect(() => {
        initState();
    }, []);

    return (
        <React.Fragment>
            <FooterMenu />
            <Header />
            {/* Hero unit */}
            <Snackbar open={showError} autoHideDuration={3000} onClose={() => { setShowError(false) }}>
                <Alert severity="warning" sx={{ width: '100%' }} onClose={() => { setShowError(false) }}>
                    Something went wrong. Please login again.
                </Alert>
            </Snackbar>
            <Snackbar open={showMessage} autoHideDuration={3000} onClose={() => { setShowMessage(false) }}>
                <Alert severity="success" sx={{ width: '100%' }} onClose={() => { setShowMessage(false) }}>
                    Profile updated successfully.
                </Alert>
            </Snackbar>
            <Container disableGutters maxWidth="md" component="main" sx={{ pt: 13, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    My Profile
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="fullName"
                                label="Full Name"
                                name="fullName"
                                value={fullName}
                                onChange={(e) => { setFullName(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="mobileNumber"
                                label="Mobile Number"
                                id="mobileNumber"
                                value={mobileNumber}
                                onChange={(e) => { setMobileNumber(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="gender-label">Gender</InputLabel>
                                <Select
                                    labelId="gender-label"
                                    id="gender"
                                    value={gender}
                                    label="Gender"
                                    onChange={(e) => { setGender(e.target.value); }}
                                >
                                    <MenuItem value={"FEMALE"}>Female</MenuItem>
                                    <MenuItem value={"MALE"}>Male</MenuItem>
                                    <MenuItem value={"OTHERS"}>Others</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date of Birth"
                                    value={dob}
                                    onChange={(newValue: any) => {
                                        setDob(new Date(newValue).getTime());
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" required />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="designation"
                                label="Designation"
                                id="designation"
                                value={designation}
                                onChange={(e) => { setDesignation(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="company"
                                label="Organization"
                                id="company"
                                value={company}
                                onChange={(e) => { setCompany(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="url"
                                name="socialLinkedIn"
                                label="LinkedIn Profile"
                                id="socialLinkedIn"
                                value={socialLinkedIn}
                                onChange={(e) => { setSocialLinkedIn(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                multiline
                                rows={4}
                                name="aboutMe"
                                label="Your Bio"
                                id="aboutMe"
                                value={aboutMe}
                                onChange={(e) => { setAboutMe(e.target.value) }}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, py: 2, fontSize: 20 }}
                    >
                        Update Profile
                    </Button>
                </Box>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="flex-end">

                </Grid>
            </Container>
            {/* Footer */}
            <Container
                maxWidth="md"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 6,
                    py: [3, 6],
                }}
            >
                <Grid container spacing={4} justifyContent="space-evenly">

                </Grid>
                <Copyright />
            </Container>
            {/* End footer */}
        </React.Fragment>
    );
}
import * as React from 'react';
import { CssBaseline, Box, Toolbar, List, Typography, Divider, IconButton, Badge, Container, Grid, Paper, Link, Alert, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Tooltip } from '@mui/material';
import Copyright from '../../common/copyright';
import Menu from '../../common/menu';
import Header from '../header';
import FooterMenu from '../menu';
import { useHistory } from 'react-router-dom';
import tokenService from '../../../utils/services/token.service';
import briefcaseService from '../../../utils/services/briefcase.service';
import { IContact } from '../../../utils/interfaces/contact.interface';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { IFile } from '../../../utils/interfaces/file.interface';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export default function Briefcase() {
    const history = useHistory();
    const [files, setFiles] = React.useState([]);
    const [contacts, setContacts] = React.useState([]);
    const [showError, setShowError] = React.useState(false);
    const [showMessage, setShowMessage] = React.useState(false);

    const initState = async () => {
        const token = await tokenService.getToken();
        if (token == null) {
            // Redirect user to login
            history.push("/login");
        } else {
            // Fetch User details
            const response = await briefcaseService.getMyBriefcase();
            if (response == null) {
                // User not found. Redirect user to login
                setShowError(true);
                setTimeout(async () => {
                    await tokenService.clearData();
                    history.push("/login");
                }, 2000);
            } else {
                const briefcase = response.data;
                console.log(briefcase);
                setFiles(briefcase.media || []);
                setContacts(briefcase.contacts || []);
            }
        }
    };

    React.useEffect(() => {
        initState();
    }, []);

    return (
        <React.Fragment>
            <FooterMenu />
            <Header />
            {/* Hero unit */}
            <Snackbar open={showError} autoHideDuration={3000} onClose={() => { setShowError(false) }}>
                <Alert severity="warning" sx={{ width: '100%' }} onClose={() => { setShowError(false) }}>
                    Something went wrong. Please login again.
                </Alert>
            </Snackbar>
            <Snackbar open={showMessage} autoHideDuration={3000} onClose={() => { setShowMessage(false) }}>
                <Alert severity="success" sx={{ width: '100%' }} onClose={() => { setShowMessage(false) }}>
                    Briefcase updated successfully.
                </Alert>
            </Snackbar>
            <Container disableGutters maxWidth="md" component="main" sx={{ pt: 13, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    My Briefcase
                </Typography>
                <Typography
                    component="h4"
                    variant="h6"
                    align="left"
                    color="text.primary"
                    gutterBottom
                >
                    Added Contacts
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="Contacts Table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Sr. No.</TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">Designation</TableCell>
                                <TableCell align="center">Company</TableCell>
                                <TableCell align="center">Social</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contacts.map((contact: { user: IContact }, index: number) => {
                                let user: IContact = contact.user;
                                return (
                                    <TableRow
                                        key={user._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="center"><Avatar alt={user.firstName + " " + user.lastName} src={user.profileImage} /></TableCell>
                                        <TableCell component="th" scope="row">
                                            {user.firstName + " " + user.lastName}
                                        </TableCell>
                                        <TableCell align="center">{user.professionalInfo?.designation}</TableCell>
                                        <TableCell align="center">{user.professionalInfo?.company}</TableCell>
                                        <TableCell align="center">
                                            {user.social?.linkedin == "" ? (
                                                null
                                            ) : <Tooltip title="Go to LinkedIn">
                                                <IconButton aria-label="Go to LinkedIn" href={user.social?.linkedin} target="_blank">
                                                    <LinkedInIcon />
                                                </IconButton>
                                            </Tooltip>}


                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography
                    component="h4"
                    variant="h6"
                    align="left"
                    color="text.primary"
                    gutterBottom
                    sx={{ mt: 3 }}
                >
                    Added Media
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="Contacts Table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Sr. No.</TableCell>
                                <TableCell align="center">File Name</TableCell>
                                <TableCell align="center">Download</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files.map((file: IFile, index: number) => (
                                <TableRow
                                    key={file._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="center">{file.fileName}</TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {file.url == "" ? (
                                            null
                                        ) : <Tooltip title="Download File">
                                            <IconButton aria-label="Download File" href={file.url} target="_blank">
                                                <CloudDownloadIcon />
                                            </IconButton>
                                        </Tooltip>}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="flex-end">

                </Grid>
            </Container>
            {/* Footer */}
            <Container
                maxWidth="md"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 6,
                    py: [3, 6],
                }}
            >
                <Grid container spacing={4} justifyContent="space-evenly">

                </Grid>
                <Copyright />
            </Container>
            {/* End footer */}
        </React.Fragment>
    );
}
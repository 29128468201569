import * as React from 'react';
import { CssBaseline, Box, Toolbar, List, Typography, Divider, IconButton, Badge, Container, Grid, Paper, Link, Alert, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Tooltip, Modal } from '@mui/material';
import Copyright from '../common/copyright';
import Menu from '../common/menu';
import Header from '../common/header';
import FooterMenu from '../common/menu';
import { useHistory } from 'react-router-dom';
import tokenService from '../../utils/services/token.service';
import briefcaseService from '../../utils/services/briefcase.service';
import { IContact } from '../../utils/interfaces/contact.interface';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { IFile } from '../../utils/interfaces/file.interface';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import boothService from '../../utils/services/booth.service';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import axios from "axios";
import CONSTANTS from './../../utils/constants';
const API_URL = CONSTANTS.API_URL + '/file';

export default function ManageBooth() {
    const history = useHistory();
    const [files, setFiles] = React.useState([]);
    const [boothId, setBoothId] = React.useState("");
    const [showError, setShowError] = React.useState(false);
    const [showMessage, setShowMessage] = React.useState(false);
    const [showFileMessage, setShowFileMessage] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);

    const [fileName, setFileName] = React.useState("");
    const [url, setUrl] = React.useState("");

    const uploadInputRef = React.useRef(null);

    const initState = async () => {
        const token = await tokenService.getToken();
        if (token == null) {
            // Redirect user to login
            history.push("/login");
        } else {
            // Fetch Booth details
            const userJson = await tokenService.getKey('user');
            const user = JSON.parse(userJson || "");
            await fetchBoothMedia(user);
        }
    };

    React.useEffect(() => {
        initState();
    }, []);

    const fetchBoothMedia = async (user: any) => {
        const response = await boothService.getBoothInfoByUserId(user?._id);
        if (response == null) {
            // User not found. Redirect user to login
            setShowError(true);
            setTimeout(async () => {
                await tokenService.clearData();
                history.push("/login");
            }, 1000);
        } else {
            if (response.data == "") {
                history.push("/");
            }
            setFiles(response.data?.media || []);
            setBoothId(response.data?._id || "");
        }
    }

    const deleteFile = async (fileId: string) => {
        try {
            const res = await boothService.deleteFileFromBooth(boothId, fileId);
            if (res == null) {
                setShowError(true);
            } else {
                setShowMessage(true);
                // Fetch Booth details
                const userJson = await tokenService.getKey('user');
                const user = JSON.parse(userJson || "");
                await fetchBoothMedia(user);
            }
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    const handleClose = () => setOpenModal(false);

    const handleFileUpload = async (e: any) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        const res = await fileUploadCall(formData);
        if (res == null) {
            setShowError(true);
        } else {
            setShowFileMessage(true);
            setUrl(res?.data[0]?.fileName);
        }
    }

    const fileUploadCall = async (data: any) => {
        try {
            const token = await tokenService.getToken();
            return await axios.post(API_URL + "/upload_files", data, {
                headers: { Authorization: 'Bearer ' + token }
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    const handleSubmit = async (event: React.FormEvent) => {
        try {
            event.preventDefault();
            const res = await boothService.addFileToBooth(boothId, fileName, url);
            if (res == null) {
                setShowError(true);
            } else {
                setOpenModal(false);
                setShowMessage(true);
                // Fetch Booth details
                const userJson = await tokenService.getKey('user');
                const user = JSON.parse(userJson || "");
                await fetchBoothMedia(user);
            }
        } catch (e) {
            console.log(e);
            throw e;
        }
    }


    return (
        <React.Fragment>
            <FooterMenu />
            <Header />
            {/* Hero unit */}
            <Snackbar open={showError} autoHideDuration={3000} onClose={() => { setShowError(false) }}>
                <Alert severity="warning" sx={{ width: '100%' }} onClose={() => { setShowError(false) }}>
                    Something went wrong. Please try again.
                </Alert>
            </Snackbar>
            <Snackbar open={showMessage} autoHideDuration={3000} onClose={() => { setShowMessage(false) }}>
                <Alert severity="success" sx={{ width: '100%' }} onClose={() => { setShowMessage(false) }}>
                    Booth updated successfully.
                </Alert>
            </Snackbar>
            <Snackbar open={showFileMessage} autoHideDuration={3000} onClose={() => { setShowFileMessage(false) }}>
                <Alert severity="success" sx={{ width: '100%' }} onClose={() => { setShowFileMessage(false) }}>
                    File uploaded. Now, add the file to your booth.
                </Alert>
            </Snackbar>
            <Container disableGutters maxWidth="md" component="main" sx={{ pt: 13, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Manage My Booth
                </Typography>
                <Typography
                    component="h4"
                    variant="h6"
                    align="left"
                    color="text.primary"
                    gutterBottom
                    sx={{ mt: 3, flex: 1 }}
                >
                    Added Media
                    <Tooltip title="Add a File">
                        <Button aria-label="Add a File" onClick={() => { setOpenModal(true) }} variant={'contained'} sx={{ color: 'primary', mx: 5 }}>Add a File</Button>
                    </Tooltip>
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="Contacts Table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Sr. No.</TableCell>
                                <TableCell align="center">File Name</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files.map((file: IFile, index: number) => (
                                <TableRow
                                    key={file._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="center">{file.fileName}</TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {file.url == "" ? (
                                            null
                                        ) : <Tooltip title="Download File">
                                            <IconButton aria-label="Download File" href={file.url} target="_blank">
                                                <CloudDownloadIcon />
                                            </IconButton>
                                        </Tooltip>}
                                        {file._id == "" ? (
                                            null
                                        ) : <Tooltip title="Delete File">
                                            <IconButton aria-label="Delete File" onClick={() => { deleteFile(file._id) }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Upload FIle Modal */}
                <Modal
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{
                        mt: 3, position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} sx={{ margin: 'auto' }}>
                                <Button
                                    variant="contained"
                                    component="label"
                                >
                                    Upload File
                                    <input
                                        type="file"
                                        multiple={false}
                                        hidden
                                        onChange={handleFileUpload}
                                    />
                                </Button>
                                {url == "" ? (
                                    null
                                ) : (
                                    <AttachFileIcon />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="fileName"
                                    label="File Name"
                                    name="fileName"
                                    value={fileName}
                                    onChange={(e) => { setFileName(e.target.value) }}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, py: 2, fontSize: 20 }}
                        >
                            Add File
                        </Button>
                    </Box>
                </Modal>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="flex-end">

                </Grid>
            </Container>
            {/* Footer */}
            <Container
                maxWidth="md"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 6,
                    py: [3, 6],
                }}
            >
                <Grid container spacing={4} justifyContent="space-evenly">

                </Grid>
                <Copyright />
            </Container>
            {/* End footer */}
        </React.Fragment>
    );
}
import axios from "axios";
import CONSTANTS from './../constants';
import tokenService from "./token.service";

const API_URL = CONSTANTS.API_URL + '/booth';

export default {
    getBoothInfo: async (boothId: string) => {
        try {
            const token = await tokenService.getToken();
            return await axios.get(API_URL + "/" + boothId, {
                headers: { Authorization: 'Bearer ' + token }
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    getBoothInfoByUserId: async (userId: string) => {
        try {
            const token = await tokenService.getToken();
            return await axios.get(API_URL + "/by_user?userId=" + userId, {
                headers: { Authorization: 'Bearer ' + token }
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    addFileToBooth: async (boothId: string, fileName: string, url: string) => {
        try {
            const token = await tokenService.getToken();
            return await axios.post(API_URL + "/files?boothId=" + boothId.toString(), {
                fileName, url
            }, {
                headers: { Authorization: 'Bearer ' + token }
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    updateFileInBooth: async (boothId: string, mediaId: string, fileName: string, url: string) => {
        try {
            const token = await tokenService.getToken();
            return await axios.put(API_URL + "/files?boothId=" + boothId.toString() + "&mediaId=" + mediaId.toString(), {
                fileName, url
            }, {
                headers: { Authorization: 'Bearer ' + token }
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    deleteFileFromBooth: async (boothId: string, mediaId: string) => {
        try {
            const token = await tokenService.getToken();
            return await axios.put(API_URL + "/delete_files?boothId=" + boothId.toString() + "&mediaId=" + mediaId.toString(), {}, {
                headers: { Authorization: 'Bearer ' + token }
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
}
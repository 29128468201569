import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import Admin from './components/admin';
import ForgotPassword from './components/auth/forgotpassword';
import Login from './components/auth/login';
import ValidateOtp from './components/auth/validateotp';
import Booth from './components/booth';

import Agenda from './components/common/agenda';
import Briefcase from './components/common/briefcase';
import ChatComponent from './components/common/chat';
import Conference from './components/conference';
import Exhibition from './components/exhibition';
import Lobby from './components/lobby';
import ManageBooth from './components/manage';
import Networking from './components/networking';
import User from './components/user';
import Users from './components/users';
import VIP from './components/vip';


class Routing extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    {/* Auth Routes */}
                    <Route path="/login" exact component={Login} />
                    <Route path="/forgotpassword" exact component={ForgotPassword} />
                    <Route path="/validate" exact component={ValidateOtp} />

                    {/* Functional Routes */}
                    <Route path="/agenda" exact component={Agenda} />
                    <Route path="/briefcase" exact component={Briefcase} />
                    <Route path="/chat" exact component={ChatComponent} />
                    <Route path="/users" exact component={Users} />
                    <Route path="/user" exact component={User} />

                    {/* Administrator Routes */}
                    <Route path="/admin" exact component={Admin} />

                    {/* Booth ID Routes */}
                    <Route path="/vendor" exact component={ManageBooth} />
                    <Route path="/booth/:boothId" exact component={Booth} />

                    {/* 3d Rendered Routes */}
                    <Route path="/exhibition" exact component={Exhibition} />
                    <Route path="/conference" exact component={Conference} />
                    <Route path="/networking" exact component={Networking} />
                    <Route path="/vip" exact component={VIP} />
                    <Route path="/" component={Lobby} />

                </Switch >
            </Router>
        );
    }
}

export default Routing;
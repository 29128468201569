import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';


export default function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 4, mb: 2 }}>
            {'Copyright © '}
            <Link color="inherit" href="https://africadigitalbanking.com/" target="_blank">
                Africa Digital Banking Summit
            </Link>{' '}
            {new Date().getFullYear()}.
        </Typography>
    );
}
import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, Toolbar, List, Typography, Divider, IconButton, Badge, Container, Grid, Paper, Link } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Copyright from '../common/copyright';
import Menu from '../common/menu';
import Header from '../common/header';
import tokenService from '../../utils/services/token.service';
import { useHistory, useParams } from 'react-router-dom';


export default function Booth() {
    const history = useHistory();
    const params: { boothId: string } = useParams();
    const [boothId, setBoothId] = React.useState("");

    const initState = async () => {
        const token = await tokenService.getToken();
        if (token == null) {
            // Redirect user to login
            history.push("/login");
        }
        history.push("/exhibition");
    };

    React.useEffect(() => {
        initState();
        setBoothId(params?.boothId);
    }, []);

    return (
        <>
            <Menu bgColor={false} />
            <Grid container spacing={2} sx={{
                overflowY: 'hidden',
            }}>
                <Grid item xs={12} sm={12} sx={{
                    overflowY: 'hidden',
                }}>
                    <img src='./../assets/img/dbs-conference-hall-bg.png' alt='DBS2021 Background' width="100%" />
                </Grid>
            </Grid>
        </>
    );
}
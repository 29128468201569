import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, Toolbar, List, Typography, Divider, IconButton, Badge, Container, Grid, Paper, Link } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Copyright from '../../common/copyright';
import Menu from '../../common/menu';
import Header from '../header';

import chatService from '../../../utils/services/chat.service';
import tokenService from '../../../utils/services/token.service';

import { RoomTypeEnum } from '../../../utils/enums/room.type.enum';

import { App as SendbirdApp, SendBirdProvider, withSendBird, OpenChannel } from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import CONSTANTS from './../../../utils/constants';

export default function ChatComponent() {
    const [userId, setUserId] = React.useState("");
    const [nickName, setNickName] = React.useState("");
    const [profileImage, setProfileImage] = React.useState("");
    const [accessToken, setAccessToken] = React.useState("");

    const sbAppId = CONSTANTS.SENDBIRD_APP_ID;

    const initState = async () => {
        // Update the user's friendlyName
        const userJson = await tokenService.getKey('user') || "";
        const user = JSON.parse(userJson);
        const firstName = user.firstName;
        const lastName = user.lastName;
        const profileImage = user.profileImage;
        // setAccessToken('920cb432b662cd01b6d744f27b52fe2b2316c9b0');
        setNickName(firstName + " " + lastName);
        setProfileImage(profileImage);
        setUserId(user._id);
    };

    React.useEffect(() => {
        initState();
    }, []);

    return (
        <>
            <Menu bgColor={false} />
            <Header />
            <Grid container spacing={2} sx={{
                mt: 10,
                px: 5,
                py: 5,
                height: '80vh'
            }}>
                {userId != "" ? (
                    <SendbirdApp appId={sbAppId} userId={userId} nickname={nickName} allowProfileEdit={true} profileUrl={profileImage} theme={'dark'} />

                    // <SendBirdProvider
                    //     appId={sbAppId}
                    //     userId={userId}
                    //     nickname={nickName} allowProfileEdit={true} profileUrl={profileImage} theme={'dark'}
                    // >
                    //     <div style={{ height: '90vh' }}>
                    //         <OpenChannel
                    //             useMessageGrouping={false} // To determine whether to use message grouping,
                    //             disableUserProfile // to determine whether to display user profile on clicking userIcons,
                    //             channelUrl="sendbird_open_channel_12440_61c4ec8610e31ca7f0a17de8f07eca04c1f2d54f" // pass your channel URL here.
                    //             fetchingParticipants={false} />
                    //     </div>
                    // </SendBirdProvider>
                ) : (
                    <></>
                )}

            </Grid>
        </>
    );
}
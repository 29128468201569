import * as React from 'react';
import { CssBaseline, Box, Toolbar, List, Typography, Divider, IconButton, Badge, Container, Grid, Paper, Link, Tooltip } from '@mui/material';
import Menu from '../common/menu';
import { useEffect } from 'react';
import tokenService from '../../utils/services/token.service';
import { useHistory } from 'react-router-dom';
import Header from '../common/header';
import BlinkBox from '../common/elements/blinkbox';


export default function Lobby() {
    const history = useHistory();

    const [initialLaunch, setInitialLaunch] = React.useState(false);
    const [blinkMatrix, setBlinkMatrix] = React.useState({
        tl: [100, 200],
        tr: [-250, 200],
        bl: [100, 450],
        br: [-250, 450]
    });

    const initState = async () => {
        const token = await tokenService.getToken();
        if (token == null) {
            // Redirect user to login
            await tokenService.clearData();
            history.push("/login");
        } else {
            const storedInitLaunch = await tokenService.getKey("user_initial_launch_indicator") || "true";
            setInitialLaunch(storedInitLaunch == "true" ? true : false);
        }
    };

    useEffect(() => {
        initState();
        const windowWidth = window.innerWidth;

        let bM = {
            tl: [windowWidth / 14.4, windowWidth / 7.2],
            tr: [-(windowWidth / 5.76), windowWidth / 7.2],
            bl: [windowWidth / 14.4, windowWidth / 3.2],
            br: [-(windowWidth / 5.76), windowWidth / 3.2],
        };
        setBlinkMatrix(bM);
    }, []);

    const onEntranceVideoEnd = async () => {
        setInitialLaunch(false);
        await tokenService.setKey("user_initial_launch_indicator", "false");
    };

    return (
        <>
            {initialLaunch ? (
                <video
                    autoPlay
                    muted
                    width="100%"
                    height="auto"
                    style={{
                        margin: '0 auto',
                        display: 'block',
                    }}
                    onEnded={onEntranceVideoEnd}
                >
                    <source src='./assets/videos/entrance-to-lobby.mp4' type='video/mp4' />
                    Your device does not support 3d rendering. Please try accessing the platform in another device.
                </video>
            ) : (
                <>
                    <Menu bgColor={true} />
                    <Header />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} sx={{
                            overflowY: 'hidden',
                            height: "100%"
                        }}>
                            <BlinkBox y={blinkMatrix.tl[1]} x={blinkMatrix.tl[0]} nav={'/conference'} title={'Go to the Conference Hall'} />

                            <BlinkBox y={blinkMatrix.tr[1]} x={blinkMatrix.tr[0]} nav={'/vip'} title={'Go to the VIP Room'} />

                            <BlinkBox y={blinkMatrix.bl[1]} x={blinkMatrix.bl[0]} nav={'/exhibition'} title={'Go to the Exhibition Area'} />

                            <BlinkBox y={blinkMatrix.br[1]} x={blinkMatrix.br[0]} nav={'/networking'} title={'Go to the Networking Lounge'} />

                            <video
                                autoPlay
                                muted
                                loop
                                width="100%"
                                height="auto"
                                style={{
                                    margin: '0 auto',
                                    display: 'block',
                                }}
                            >
                                <source src='./assets/videos/lobby-still.mp4' type='video/mp4' />
                                Your device does not support 3d rendering. Please try accessing the platform in another device.
                            </video>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, Toolbar, List, Typography, Divider, IconButton, Badge, Container, Grid, Paper, Link } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Copyright from '../common/copyright';
import Menu from '../common/menu';
import Header from '../common/header';
import tokenService from '../../utils/services/token.service';
import { useHistory } from 'react-router-dom';

import { App as SendbirdApp, SendBirdProvider, withSendBird, OpenChannel } from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import CONSTANTS from './../../utils/constants';

export default function Networking() {
    const history = useHistory();
    const [userId, setUserId] = React.useState("");
    const [nickName, setNickName] = React.useState("");
    const [profileImage, setProfileImage] = React.useState("");

    const [initialLaunch, setInitialLaunch] = React.useState(true);

    const sbAppId = CONSTANTS.SENDBIRD_APP_ID;

    const initState = async () => {
        const token = await tokenService.getToken();
        if (token == null) {
            // Redirect user to login
            history.push("/login");
        }
        // Update the user's friendlyName
        const userJson = await tokenService.getKey('user') || "";
        const user = JSON.parse(userJson);
        const firstName = user.firstName;
        const lastName = user.lastName;
        const profileImage = user.profileImage;
        // setAccessToken('920cb432b662cd01b6d744f27b52fe2b2316c9b0');
        setNickName(firstName + " " + lastName);
        setProfileImage(profileImage);
        setUserId(user._id);
    };

    React.useEffect(() => {
        initState();
    }, []);

    const onEntranceVideoEnd = async () => {
        setInitialLaunch(false);
    };

    return (
        <>
            {initialLaunch ? (
                <video
                    autoPlay
                    muted
                    width="100%"
                    height="auto"
                    style={{
                        margin: '0 auto',
                        display: 'block',
                    }}
                    onEnded={onEntranceVideoEnd}
                >
                    <source src='./assets/videos/lobby-to-networking-door.mp4' type='video/mp4' />
                    Your device does not support 3d rendering. Please try accessing the platform in another device.
                </video>
            ) : (
                <>
                    <Menu bgColor={false} />
                    <Header />
                    <Grid container spacing={2}>
                        <Grid container spacing={2} sx={{
                            mt: 10,
                            px: 5,
                            py: 5,
                            position: 'absolute',
                            top: -40,
                            height: '70vh',
                            width: '100vh',
                            zIndex: 999,
                        }}>
                            {userId != "" ? (
                                // <SendbirdApp appId={sbAppId} userId={userId} nickname={nickName} allowProfileEdit={true} profileUrl={profileImage} theme={'dark'} />

                                <SendBirdProvider
                                    appId={sbAppId}
                                    userId={userId}
                                    nickname={nickName} allowProfileEdit={true} profileUrl={profileImage} theme={'dark'}
                                >
                                    <div style={{
                                        height: '70vh',
                                        width: '100vh'
                                    }}>
                                        <OpenChannel
                                            useMessageGrouping={false} // To determine whether to use message grouping,
                                            disableUserProfile // to determine whether to display user profile on clicking userIcons,
                                            channelUrl="sendbird_open_channel_12440_61c4ec8610e31ca7f0a17de8f07eca04c1f2d54f" // pass your channel URL here.
                                            fetchingParticipants={false} />
                                    </div>
                                </SendBirdProvider>
                            ) : (
                                <></>
                            )}

                        </Grid>
                        <Grid item xs={12} sm={12} sx={{
                            overflowY: 'hidden',
                            height: "100%"
                        }}>
                            <video
                                autoPlay
                                muted
                                loop
                                width="100%"
                                height="auto"
                                style={{
                                    margin: '0 auto',
                                    display: 'block',
                                }}
                            >
                                <source src='./assets/videos/networking-lounge-still.mp4' type='video/mp4' />
                                Your device does not support 3d rendering. Please try accessing the platform in another device.
                            </video>


                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
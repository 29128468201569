import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../../common/copyright';
import authService from '../../../utils/services/auth.service';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Alert, FormHelperText, Snackbar } from '@mui/material';
import tokenService from '../../../utils/services/token.service';

const theme = createTheme();

export default function ForgotPassword() {
    const history = useHistory();
    const [showError, setShowError] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            const data = new FormData(event.currentTarget);
            // eslint-disable-next-line no-console
            console.log({
                email: data.get('email'),
            });

            const emailId: string = data.get('email')?.toString() || "";

            const response = await authService.forgotPassword(emailId);
            setShowMessage(true);
            await tokenService.setKey("email", emailId);

            setTimeout(() => {
                history.push("/validate");
            }, 2000);
        } catch (e) {
            console.log(e);
            setShowError(true);
        }
    };

    return (
        <ThemeProvider theme={theme} >
            <Snackbar open={showError} autoHideDuration={3000} onClose={() => { setShowError(false) }}>
                <Alert severity="warning" sx={{ width: '100%' }} onClose={() => { setShowError(false) }}>
                    Invalid email address.
                </Alert>
            </Snackbar>
            <Snackbar open={showMessage} autoHideDuration={3000} onClose={() => { setShowMessage(false) }}>
                <Alert severity="success" sx={{ width: '100%' }} onClose={() => { setShowMessage(false) }}>
                    OTP Emailed successfully. Please check your email.
                </Alert>
            </Snackbar>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Enter your registered email
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Send OTP Email
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <></>
                            </Grid>
                            <Grid item>
                                <Link href="/login" variant="body2">
                                    {"Remember your password? Login here"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright />
            </Container>
        </ThemeProvider>
    );
}
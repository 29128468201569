import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, Toolbar, List, Typography, Divider, IconButton, Badge, Container, Grid, Paper, Link, AppBar, Button, Card, CardActions, CardContent, CardHeader, GlobalStyles, Tooltip, Avatar, Menu, MenuItem, ListItemIcon } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Copyright from '../common/copyright';
import { Logout, PersonAdd, Settings, SettingsInputAntennaTwoTone } from '@mui/icons-material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import StoreIcon from '@mui/icons-material/Store';
import tokenService from '../../utils/services/token.service';
import { useHistory } from 'react-router-dom';

interface HeaderProps {
    userInitial?: string;
    notificationCount?: number;
    isVendor?: boolean;
    isAdmin?: boolean;
}

export default function Header(props: HeaderProps) {
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [userInitial, setUserInitial] = React.useState<string>("");
    const [notificationCount, setNotificationCount] = React.useState<number>(0);
    const [isVendor, setIsVendor] = React.useState<boolean>(false);
    const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
    const [profileImage, setProfileImage] = React.useState("");

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const logoutUser = async () => {
        await tokenService.clearData();
        history.push("/login");
    };
    const redirectToBriefcase = () => {
        history.push("/briefcase");
    };
    const redirectToUser = () => {
        history.push("/user");
    };
    const redirectToVendor = () => {
        history.push("/vendor");
    };
    const redirectToAdmin = () => {
        history.push("/admin");
    };

    const initState = async () => {
        const userJson = await tokenService.getKey('user');
        if (userJson == null) {
            // Redirect user to login
            history.push("/login");
        } else {
            // Update Booth Mgmt Menu Item
            const user = JSON.parse(userJson);
            if (user.userType == "VENDOR") {
                setIsVendor(true);
            } else {
                setIsVendor(false);
            }

            if (user.userType == "ADMIN") {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
            setUserInitial(user.firstName[0]);
            setProfileImage(user.profileImage);
        }
    };

    React.useEffect(() => {
        setUserInitial(props.userInitial || "⚡");
        setNotificationCount(props.notificationCount || 0);
        setIsVendor(props.isVendor || false);
        setIsAdmin(props.isAdmin || false);
        initState();
        return;
    }, [props]);

    return (
        <React.Fragment>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <AppBar
                position="fixed"
                color="default"
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
            >
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        <img src="./assets/img/dbs-logo.png" alt="DBS2021" width="90" />
                    </Typography>

                    <IconButton color="inherit">
                        <Badge badgeContent={notificationCount} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <Tooltip title="Account settings">
                        <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
                            <Avatar src={profileImage} sx={{ width: 32, height: 32 }}>{userInitial}</Avatar>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={redirectToUser}>
                    <Avatar src={profileImage} /> My Profile
                </MenuItem>
                <MenuItem onClick={redirectToBriefcase}>
                    <BusinessCenterIcon sx={{ mr: 1 }} /> My Briefcase
                </MenuItem>
                <Divider />
                {isVendor ? (
                    <MenuItem onClick={redirectToVendor}>
                        <StoreIcon sx={{ mr: 1 }} /> My Booth
                    </MenuItem>

                ) : null}

                {isAdmin ? (
                    <MenuItem onClick={redirectToAdmin}>
                        <StoreIcon sx={{ mr: 1 }} /> Admin Access
                    </MenuItem>

                ) : null}


                <MenuItem onClick={logoutUser} >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
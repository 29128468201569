import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Alert, IconButton, Snackbar, Tooltip } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import briefcaseService from '../../utils/services/briefcase.service';
import { IContact } from '../../utils/interfaces/contact.interface';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import { IUser } from '../../utils/interfaces/user.interface';

interface UserProp {
    firstName: string;
    lastName: string;
    profileImage: string;
    designation: string;
    company: string;
    socialLinkedIn: string;
    userId: string;
    onClickView: () => void;
    userAddedToBC: boolean;
    user: IUser;
    selfCard: boolean;
}

const tempUser: IUser = {
    _id: '',
    firstName: '',
    lastName: '',
    profileImage: '',
    professionalInfo: {
        designation: '',
        company: ''
    },
    social: {
        linkedin: ''
    }
};

export default function UserCard(props: UserProp) {
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [profileImage, setProfileImage] = React.useState("");
    const [designation, setDesignation] = React.useState("");
    const [company, setCompany] = React.useState("");
    const [socialLinkedIn, setSocialLinkedIn] = React.useState("");
    const [userId, setUserId] = React.useState("");
    const [onClickView, setOnClickView] = React.useState<(user: IUser) => void>(() => { });
    const [userAddedToBC, setUserAddedToBC] = React.useState(false);
    const [user, setUser] = React.useState<IUser>(tempUser);
    const [selfCard, setSelfCard] = React.useState(false);
    const [showMessage, setShowMessage] = React.useState(false);

    const initState = async () => {

    }

    React.useEffect(() => {
        setFirstName(props.firstName);
        setLastName(props.lastName);
        setProfileImage(props.profileImage);
        setDesignation(props.designation);
        setCompany(props.company);
        setSocialLinkedIn(props.socialLinkedIn);
        setUserId(props.userId);
        setUser(props.user);
        setOnClickView(props.onClickView);
        setUserAddedToBC(props.userAddedToBC);
        setSelfCard(props.selfCard);
        initState();
        return;
    }, [props]);

    const addUserToBriefcase = async () => {
        try {
            const res = await briefcaseService.addContactToBriefcase(userId);
            if (res == null) {
                // Unknown Error
                console.log("Error while adding to briefcase.");
            } else {
                setUserAddedToBC(true);
                setShowMessage(true);
            }
        } catch (e) {
            console.log(e);
            throw e;
        }
    };

    return (
        <Card sx={{ maxWidth: 345 }}>
            <Snackbar open={showMessage} autoHideDuration={3000} onClose={() => { setShowMessage(false) }}>
                <Alert severity="success" sx={{ width: '100%' }} onClose={() => { setShowMessage(false) }}>
                    Successfully added to briefcase
                </Alert>
            </Snackbar>
            <CardMedia
                component="img"
                height="140"
                image={profileImage}
                alt={firstName}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {firstName + " " + lastName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {designation + ", " + company}
                </Typography>
            </CardContent>
            <CardActions>
                <Button variant="contained" size="small" >View</Button>
                {
                    selfCard == true ? (
                        <Typography variant="body2" color="text.secondary">
                            &nbsp;
                        </Typography>
                    ) : userAddedToBC == true ? (
                        <Tooltip title="Added to Briefcase">
                            <IconButton aria-label="Contact already added to Briefcase" sx={{ color: 'green' }} >
                                <FileDownloadDoneIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Add to Briefcase">
                            <IconButton aria-label="Add to Briefcase" onClick={addUserToBriefcase}>
                                <BusinessCenterIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
                {socialLinkedIn == "" ? (
                    null
                ) : <Tooltip title="Go to LinkedIn">
                    <IconButton aria-label="Go to LinkedIn" href={socialLinkedIn} target="_blank">
                        <LinkedInIcon />
                    </IconButton>
                </Tooltip>}
            </CardActions>
        </Card >
    );
}
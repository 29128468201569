import axios from "axios";
import CONSTANTS from './../constants';
import tokenService from "./token.service";

const API_URL = CONSTANTS.API_URL + '/auth/';

export default {
    loginUser: async (emailId: string, password: string) => {
        try {
            return await axios.post(API_URL + "login", { emailId, password }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    forgotPassword: async (emailId: string) => {
        try {
            return await axios.post(API_URL + "forgot_password", { emailId }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    validateOtp: async (emailId: string, otp: number, newPassword: string) => {
        try {
            return await axios.post(API_URL + "validate_otp", { emailId, otp, newPassword }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    resendOtp: async (emailId: string) => {
        try {
            return await axios.post(API_URL + "resend_otp", { emailId }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    createUser: async (firstName: string, lastName: string, emailId: string, password: string, designation: string, company: string) => {
        try {
            const data = {
                "firstName": firstName,
                "lastName": lastName,
                "emailId": emailId,
                "password": password,
                "designation": designation,
                "company": company
            };
            const token = await tokenService.getToken();
            return await axios.post(API_URL + "register", data, {
                headers: { Authorization: 'Bearer ' + token }
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
}
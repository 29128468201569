export default {
    setToken: async (token: string) => {
        try {
            window.localStorage.setItem("token", token);
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    getToken: async () => {
        try {
            return window.localStorage.getItem("token");
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    setKey: async (key: string, value: string) => {
        try {
            window.localStorage.setItem(key, value);
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    getKey: async (key: string) => {
        try {
            return window.localStorage.getItem(key);
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    clearData: async () => {
        try {
            window.localStorage.removeItem("token");
            window.localStorage.clear();
            return;
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
}
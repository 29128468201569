import * as React from 'react';
import { CssBaseline, Box, Toolbar, List, Typography, Divider, IconButton, Badge, Container, Grid, Paper, Link, Tab, Tabs, Button } from '@mui/material';
import Copyright from '../../common/copyright';
import Menu from '../../common/menu';
import Header from '../header';
import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent } from '@mui/lab';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import StarsIcon from '@mui/icons-material/Stars';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function Agenda() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Menu />
            <Header />
            <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 13, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Summit Agenda
                </Typography>
                <Tabs value={value} onChange={handleChange} aria-label="Summit Agenda" centered>
                    <Tab label="Day One - 10th Nov" {...a11yProps(0)} />
                    <Tab label="Day Two - 11th Nov" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <Timeline position="alternate">
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                08:15 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot>
                                    <AssignmentIndOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Registration & Platform Walkthrough
                                </Typography>
                                <Typography>
                                    <Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                08:30 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <ImportContactsOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Welcome Address
                                </Typography>
                                <Typography><b>Mohammed Thoufiq</b>, Partnership Director, ICSA</Typography>
                                <Typography><Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button></Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                08:35 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <AccountCircleOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Opening Remarks from the Chairwomen
                                </Typography>
                                <Typography><b>Gillian Hammah</b>, Group Chief Marketing Officer, Databank Financial Services</Typography>
                                <Typography><Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button></Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                08:40 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                <TimelineDot color="secondary">
                                    <AccountCircleOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Inaugural Keynote: Recommendations for Post Covid Recovery: Policy and Regulatory Frameworks for Banks & FIs.
                                </Typography>
                                <Typography><b>Dr. Maxwell Opoku-Afari</b>, First Deputy Governor, Bank of Ghana</Typography>
                                <Typography>
                                    <Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                09:00 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    The Future of Finance is Transformation
                                </Typography>
                                <Typography><b>Ronald Raffensperger</b>, CTO, Digital Transformation – Financial Industry Business Huawei</Typography>
                                <Typography>
                                    <Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                09:20 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="secondary" variant="outlined">
                                    <SupervisedUserCircleOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    C-Suite Panel: The Roadmap to a truly Digital Bank
                                </Typography>
                                <Typography><b>Aziz Faye</b>, Chief Information Officer, EcoBank</Typography>
                                <Typography><b>Victor Yaw Asante</b>, Managing Director and Chief Executive Officer, FBNBank Ghana</Typography>
                                <Typography><b>Emmanuel Morka</b>, Chief Information Officer, Access Bank Ghana</Typography>
                                <Typography><b>Hoda Younan</b>, Marketing and Operations Director, Microsoft Middle East and Africa – Multi-Country Region</Typography>
                                <Typography><b>Krinal Gupta</b>, Vice President and Business Head  - Africa, Profinch Solutions</Typography>
                                <Typography><b>Moderator – Erwin Van Helden</b>, Global Banking Expert</Typography>

                                <Typography><Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button></Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                10:00 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Potential Implications Post Pandemic on the Banking Sector and Best Recovery Methods
                                </Typography>
                                <Typography><b>Okyere Kwasi Akyeampong</b>, Chief Transformation Officer, Fidelity Bank Ghana</Typography>
                                <Typography><Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button></Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                10:20 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Adoption of Emerging Technologies, Challenges in the Process of Acceleration
                                </Typography>
                                <Typography><b>Hon. Engr Solomon Adaelu</b>, Member of Parliament at National Assembly of Nigeria (NASS)</Typography>
                                <Typography>
                                    <Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                10:40 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Topic TBC – (Kanchi)
                                </Typography>
                                <Typography><b>Dr. Konstantin Tsanis</b>, Digital Transformation and FinTech Industry Specialist, IFC - International Finance Corporation</Typography>
                                <Typography>
                                    <Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                11:00 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Hybrid Work is here to stay, Learning and Best Practices
                                </Typography>
                                <Typography><b>Ahmed Yahyaoui</b>, Customer Success Director, Microsoft, MEA</Typography>
                                <Typography><Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button></Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                11:20 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Attaining Cyber Resilience in Banking Security
                                </Typography>
                                <Typography><b>Favour Femi-Oyowole</b>, Group Chief Information Security Officer, Access Bank PLC</Typography>
                                <Typography><Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button></Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                11:40 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="secondary" variant="outlined">
                                    <SupervisedUserCircleOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    C-Suite Colloquy – Open Innovation with Technology as a Catalyst
                                </Typography>
                                <Typography><b>Sidy Kounta</b>, Managing Director, Atlantique Microfinance Bank, Mali</Typography>
                                <Typography><b>Ngozi Egbuna</b>, Regional Trade and Payment Integration Expert, Social Entrepreneur</Typography>
                                <Typography><b>Confidence Staveley</b>, Founder and Executive Director, CyberSafe Foundation</Typography>
                                <Typography><b>Carl Sackey</b>, Past President, ISACA Ghana Chapter</Typography>
                                <Typography><b>Frank Atube</b>, Chief Operating Officer, Seamfix</Typography>
                                <Typography><b>Moderator – Andrew Akoto</b>, Head of Advisory and Partner, Risk Consulting Services KPMG Ghana</Typography>
                                <Typography>
                                    <Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                12:20 pm
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    The Future of Banking in Africa
                                </Typography>
                                <Typography><b>Thairu Ndungu</b>, Deputy Managing Director, Consolidated Bank of Ghana</Typography>
                                <Typography>
                                    <Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                12:40 pm
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Banking in the Digital Customer Age
                                </Typography>
                                <Typography><b>Javier Hermida</b>, Head of Digital Banking, Middle East and Africa, Temenos</Typography>
                                <Typography><Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button></Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                01:00 pm
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Banking Security: Getting Cybersecurity Awareness Right
                                </Typography>
                                <Typography><b>Confidence Staveley</b>, Founder and Executive Director, CyberSafe Foundation</Typography>
                                <Typography><Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button></Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                13:20 pm
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Digitization as a Key to Financial Inclusion – An Economist’s Purview
                                </Typography>
                                <Typography><b>Ngozi Egbuna</b>, Economist, Regional Trade and Payment Integration Expert, Social Entrepreneur</Typography>
                                <Typography>
                                    <Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Timeline position="alternate">
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                08:15 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot>
                                    <AssignmentIndOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Registration & Platform Walkthrough
                                </Typography>
                                <Typography>
                                    <Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                08:30 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <ImportContactsOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Welcome Address
                                </Typography>
                                <Typography><b>ICSA</b></Typography>
                                <Typography><Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button></Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                08:35 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <AccountCircleOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Opening Remarks from the Chairwomen
                                </Typography>
                                <Typography><b>Gillian Hammah</b>, Group Chief Marketing Officer, Databank Financial Services</Typography>
                                <Typography><Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button></Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                08:40 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Cash is King : Cash-less is the Emperor – The penetration of Digital Money
                                </Typography>
                                <Typography><b>Victor Yaw Asante</b>, Managing Director and Chief Executive Officer, FBNBank Ghana</Typography>
                                <Typography>
                                    <Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                09:00 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Digital Financial Inclusion
                                </Typography>
                                <Typography><b>Krinal Gupta</b>, Vice President and Business Head  - Africa, Profinch Solutions</Typography>
                                <Typography>
                                    <Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                09:20 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="secondary" variant="outlined">
                                    <SupervisedUserCircleOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Panel Discussion: What’s the future of Banking: NeoBanks and Challenger Banks
                                </Typography>
                                <Typography><b>Dele Adeyinka</b>, Chief Digital Officer, Polaris Bank</Typography>
                                <Typography><b>Olufemi Olofintila</b>, CISO, Nova Merchant Bank</Typography>
                                <Typography><b>Rachel Adeshina</b>, Country Head, Technology and Services, FBNBank Ghana</Typography>
                                <Typography><b>Dina Marie</b>, Co-Founder & Former CEO, HR Revolution Middle-East Magazine</Typography>
                                <Typography><b>Javier Hermida</b>, Head of Digital Banking, Middle East and Africa, Temenos</Typography>
                                <Typography><b>Udeet Bhagat</b>, Business Head, Arttha by PureSoftware</Typography>
                                <Typography><b>Moderator – Mohammed Thoufiq</b>, Director – Partnerships, International Center for Strategic Alliances</Typography>

                                <Typography><Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button></Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                10:00 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Transforming Customer Experience - The Key Pillar Insights
                                </Typography>
                                <Typography><b>Calleb Osei</b>, Regional CFO, Guaranty Trust Bank, West Africa & Chief Finance Officer, Guaranty Trust Bank Ghana Limited</Typography>
                                <Typography><Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button></Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                10:20 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Digital Identity and Financial Security
                                </Typography>
                                <Typography><b>Frank Atube</b>, Chief Operating Officer, Seamfix</Typography>
                                <Typography>
                                    <Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                10:40 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Managing Risks in the Digital Age- The Interconnectivities
                                </Typography>
                                <Typography><b>Bamidele Oseni</b>, Managing Partner, Caleb Consulting</Typography>
                                <Typography>
                                    <Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                11:00 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Industry Keynote
                                </Typography>
                                <Typography><b>Benjamin Dzoboku</b>, Chief Operating Officer, Republic Bank Ghana</Typography>
                                <Typography><Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button></Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                11:20 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Redefining the Eco-System through Fintech; Financial Inclusion Scope
                                </Typography>
                                <Typography><b>Aly Mansy</b>, Economist & Strategic Expert</Typography>
                                <Typography><Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button></Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                11:40 am
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <RecordVoiceOverOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    The Role of Banking in Achieving SDGs
                                </Typography>
                                <Typography><b>Mahmoud Mansi</b>, Founder & Chairperson, HR Revolution Middle East Magazine</Typography>
                                <Typography>
                                    <Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                12:00 noon
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="secondary" variant="outlined">
                                    <StarsIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    3rd Annual Africa Digital Banking Awards 2021
                                </Typography>
                                <Typography>
                                    <Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button>
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                12:30 pm
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="secondary" variant="outlined">
                                    <ImportContactsOutlinedIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Concluding Remarks by the Chairwoman
                                </Typography>
                                <Typography><b>Gillian Hammah</b>, Group Chief Marketing Officer, Databank Financial Services.</Typography>
                                <Typography><Button href="/conference" variant="contained" sx={{ mt: 1 }} >Join</Button></Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                12:40 pm
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="secondary" variant="outlined">
                                    <ThumbUpOffAltIcon />
                                </TimelineDot>
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    Close of Conference
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                </TabPanel>
            </Container>
            {/* Footer */}
            <Container
                maxWidth="md"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 6,
                    py: [3, 6],
                }}
            >
                <Grid container spacing={4} justifyContent="space-evenly">

                </Grid>
                <Copyright />
            </Container>
            {/* End footer */}
        </>
    );
}
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import ScheduleIcon from '@mui/icons-material/Schedule';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PersonIcon from '@mui/icons-material/Person';
import ForumIcon from '@mui/icons-material/Forum';
import PeopleIcon from '@mui/icons-material/People';
import { Grid, Paper, Tooltip } from '@mui/material';
import { useState } from 'react';

export default function Menu(props: any) {
    const [menuItem, setMenuItem] = useState(0);
    const [bgColor, setBgColor] = useState(false);

    React.useEffect(() => {
        setBgColor(props?.bgColor);
    }, [props]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: '100%',
                color: bgColor ? '#001832' : '#FFFFFF',
                position: 'fixed',
                bottom: 0,
                zIndex: 999,
            }}
        >
            <Box
                component="footer"
                sx={{
                    py: 6,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: 'transparent'
                }}
            >
                <Grid container
                    textAlign="center">
                    <CssBaseline />
                    <Grid item xs={3}>
                        <Tooltip title="Lobby">
                            <Link href="/lobby" color="inherit">
                                <HomeIcon sx={{
                                    fontSize: 60,
                                    transition: "transform 0.15s ease-in-out",
                                    "&:hover": {
                                        transform: "scale(2)"
                                    }
                                }}></HomeIcon>
                            </Link>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="Agenda">
                            <Link href="/agenda" color="inherit">
                                <ScheduleIcon sx={{
                                    fontSize: 60,
                                    transition: "transform 0.15s ease-in-out",
                                    "&:hover": {
                                        transform: "scale(2)"
                                    }
                                }}></ScheduleIcon>
                            </Link>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="My Conversations">
                            <Link href="/chat" color="inherit">
                                <ForumIcon sx={{
                                    fontSize: 60,
                                    transition: "transform 0.15s ease-in-out",
                                    "&:hover": {
                                        transform: "scale(2)"
                                    }
                                }}></ForumIcon>
                            </Link>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="All Delegates">
                            <Link href="/users" color="inherit">
                                <PeopleIcon sx={{
                                    fontSize: 60,
                                    transition: "transform 0.15s ease-in-out",
                                    "&:hover": {
                                        transform: "scale(2)"
                                    }
                                }}></PeopleIcon>
                            </Link>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
import axios from "axios";
import CONSTANTS from './../constants';
import tokenService from "./token.service";

const API_URL = CONSTANTS.API_URL + '/user/';

export default {
    getMyBriefcase: async () => {
        try {
            const token = await tokenService.getToken();
            return await axios.get(API_URL + "briefcase", {
                headers: { Authorization: 'Bearer ' + token }
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    addFileToBriefcase: async (fileId: string) => {
        try {
            const token = await tokenService.getToken();
            return await axios.post(API_URL + "briefcase_files?fileId=" + fileId.toString(), {}, {
                headers: { Authorization: 'Bearer ' + token }
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    addContactToBriefcase: async (contactId: string) => {
        try {
            const token = await tokenService.getToken();
            return await axios.post(API_URL + "briefcase_contacts?contactId=" + contactId.toString(), {}, {
                headers: { Authorization: 'Bearer ' + token }
            }).catch(e => {
                throw e.response.data.message;
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
}
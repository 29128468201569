import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      paper: '#001832',
      default: '#000E1E',
    },
  },
  typography: {
    fontFamily: `"Poppins", sans-serif`,
  },
  shape: {
    borderRadius: 18
  }
});

export default theme;

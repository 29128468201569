import * as React from 'react';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import { useHistory } from 'react-router';
import { Tooltip } from '@mui/material';

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 0.25; }
`;

const InnerBlinkedBox = styled('div')({
    backgroundColor: '#E9E9E9',
    zIndex: 999,
    width: 40,
    height: 40,
    borderRadius: '50%',
    position: "absolute",
    top: 30,
    left: 30,
    animation: `${blink} 1s ease-in-out infinite`,
});

const BlinkedBox = styled('div')({
    backgroundColor: '#E9E9E9',
    zIndex: 999,
    width: 70,
    height: 70,
    borderRadius: '50%',
    position: "absolute",
    left: 15,
    top: 15,
    animation: `${blink} 1s ease-in-out infinite`,
});

const OuterBlinkedBox = styled('div')({
    backgroundColor: '#E9E9E9',
    zIndex: 999,
    width: 100,
    height: 100,
    borderRadius: '50%',
    position: "absolute",
    top: 0,
    left: 0,
    animation: `${blink} 1s ease-in-out infinite`,
});

export default function MiniBlinkBox(props: any) {
    const history = useHistory();
    const [nav, setNav] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [x, setX] = React.useState(0);
    const [y, setY] = React.useState(0);

    React.useEffect(() => {
        setNav(props?.nav || '');
        setTitle(props?.title || '');
        setX(props?.x || 0);
        setY(props?.y || 0);
    }, [props]);

    const redirectUser = () => {
        history.push(nav);
    };

    return <>
        <Tooltip title={title} placement="bottom">
            {x < 0 ? (
                <div style={{
                    top: y,
                    right: (0 - x),
                    cursor: 'pointer',
                    position: 'absolute',
                }} onClick={redirectUser}>
                    <InnerBlinkedBox />
                    <BlinkedBox />
                    <OuterBlinkedBox />
                </div>
            ) : (
                <div style={{
                    top: y,
                    left: x,
                    cursor: 'pointer',
                    position: 'absolute',
                }} onClick={redirectUser}>
                    <InnerBlinkedBox />
                    <BlinkedBox />
                    <OuterBlinkedBox />
                </div>
            )}
        </Tooltip>

    </>;
}

import * as React from 'react';
import { CssBaseline, Box, Toolbar, List, Typography, Divider, IconButton, Badge, Container, Grid, Paper, Link, Alert, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Tooltip, Modal } from '@mui/material';
import Copyright from '../common/copyright';
import Menu from '../common/menu';
import Header from '../common/header';
import FooterMenu from '../common/menu';
import { useHistory } from 'react-router-dom';
import tokenService from '../../utils/services/token.service';
import userService from '../../utils/services/user.service';
import authService from '../../utils/services/auth.service';


export default function Admin() {
    const history = useHistory();
    const [showError, setShowError] = React.useState(false);
    const [showMessage, setShowMessage] = React.useState(false);

    const [fullName, setFullName] = React.useState("");
    const [emailId, setEmailId] = React.useState("");
    const [designation, setDesignation] = React.useState("");
    const [company, setCompany] = React.useState("");
    const [password, setPassword] = React.useState("");

    const initState = async () => {
        const token = await tokenService.getToken();
        if (token == null) {
            // Redirect user to login
            history.push("/login");
        } else {

        }
    };

    React.useEffect(() => {
        initState();
    }, []);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const firstName = fullName.split(" ")[0];
        let lastNameArray = fullName.split(" ");
        lastNameArray.splice(0, 1);
        const lastName = (lastNameArray.length > 0 ? lastNameArray.join(" ") : "");

        const res = await authService.createUser(firstName, lastName, emailId, password, designation, company);
        if (res == null) {
            setShowError(true);
        } else {
            setShowMessage(true);
            setFullName("");
            setEmailId("");
            setPassword("");
            setDesignation("");
            setCompany("");
        }

    }

    return (
        <React.Fragment>
            <FooterMenu />
            <Header />
            {/* Hero unit */}
            <Snackbar open={showError} autoHideDuration={3000} onClose={() => { setShowError(false) }}>
                <Alert severity="warning" sx={{ width: '100%' }} onClose={() => { setShowError(false) }}>
                    Something went wrong. Please try again.
                </Alert>
            </Snackbar>
            <Snackbar open={showMessage} autoHideDuration={3000} onClose={() => { setShowMessage(false) }}>
                <Alert severity="success" sx={{ width: '100%' }} onClose={() => { setShowMessage(false) }}>
                    User created successfully.
                </Alert>
            </Snackbar>

            <Container disableGutters maxWidth="md" component="main" sx={{ pt: 13, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Create new Users
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="fullName"
                                label="Full Name"
                                name="fullName"
                                value={fullName}
                                onChange={(e) => { setFullName(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="emailId"
                                label="Email"
                                name="emailId"
                                value={emailId}
                                onChange={(e) => { setEmailId(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="designation"
                                label="Designation"
                                id="designation"
                                value={designation}
                                onChange={(e) => { setDesignation(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="company"
                                label="Organization"
                                id="company"
                                value={company}
                                onChange={(e) => { setCompany(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="password"
                                label="Password"
                                id="password"
                                value={password}
                                onChange={(e) => { setPassword(e.target.value) }}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, py: 2, fontSize: 20 }}
                    >
                        Create User
                    </Button>
                </Box>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="flex-end">

                </Grid>
            </Container>
            {/* Footer */}
            <Container
                maxWidth="md"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 6,
                    py: [3, 6],
                }}
            >
                <Grid container spacing={4} justifyContent="space-evenly">

                </Grid>
                <Copyright />
            </Container>
            {/* End footer */}
        </React.Fragment>
    );
}